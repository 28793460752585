import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import styled from '@emotion/styled';

import Layout from '../components/Layout';
import SEO from '../components/seo';
import { LinkRow } from '../components/Notes/LinkRow';
import { slugToTitle } from '../../utils/slug';
import { sortNodes } from '../utility/sort';
import Subheader from '../components/Subheader';

const Container = styled.div``;

const Notes = () => {
  const data = useStaticQuery(graphql`
    query NotesQuery {
      allDirectory {
        nodes {
          absolutePath
          relativePath
          relativeDirectory
          dir
          name
          fields {
            slug
            name
          }
        }
      }
      allFile(filter: { fields: { slug: { ne: null } }, internal: { mediaType: { eq: "text/html" } } }) {
        nodes {
          absolutePath
          dir
          relativeDirectory
          relativePath
          name
          fields {
            slug
            name
          }
        }
      }
    }
  `);
  const raw = [...data.allDirectory.nodes, ...data.allFile.nodes];

  const filtered = raw
    .filter(r => r.relativeDirectory === '')
    .filter(r => r.fields)
    .sort(sortNodes);

  return (
    <Container>
      {filtered.map(r => {
        return (
          <LinkRow key={r.name}>
            <Link to={r.fields.slug}>{slugToTitle(r.name)}</Link>
          </LinkRow>
        );
      })}
    </Container>
  );
};

export default Notes;
